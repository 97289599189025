<template>
  <div class="questions">
    <div class="radio">
      <!-- <img src="../assets/radio-shadow.svg" class="radio-shadow" alt="Radio shadow">
      <img src="../assets/radio-clean.svg" class="radio-image" alt="Radio"> -->
      <div class="knob" :style="{ 'transform': 'rotate('+rotation+'deg)'}"></div>
      <div class="knob-touch" ref="toucharea" v-touch:tap="knobClick" v-touch:press="knobDragStart" v-touch:drag="knobDragMove" v-touch:release="knobDragRelease"></div>

      <div class="display">{{ number }}</div>
      <div class="ok-shadow"></div>
      <button class="ok" @click="clickOk" :class="[currentQuestionHasChanged?'ok-enabled':'ok-disabled', makeOkButtonJump?'jump':'']">OK</button>

      <div class="question" :class="['question-'+currentQuestion, isShowingQuestions?'is-question':'']" v-if="currentQuestion >= 0">
        {{ currentText }}
      </div>

      <transition name="fadedelay">
        <button class="start" @click="clickStart" v-if="makeOkButtonJump">Gör testet</button>
      </transition>

      <ul class="indicators">
        <li :class="(currentQuestion == 2)?'active':'inactive'"></li>
        <li :class="(currentQuestion == 3)?'active':'inactive'"></li>
        <li :class="(currentQuestion == 4)?'active':'inactive'"></li>
        <li :class="(currentQuestion == 5)?'active':'inactive'"></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: 'Questions',
  emits: ["next"],
  props: {
    triggerAnimation: Boolean
  },
  watch: {
    triggerAnimation() {
      const INITIAL_DELAY = 4; 
      gsap.to(this, { rotation: -40, ease: 'sine.inOut', delay: INITIAL_DELAY+2, duration: 1 });
      gsap.to(this, { rotation: 40, ease: 'sine.inOut', delay: INITIAL_DELAY+3, duration: 1 });
      gsap.to(this, { rotation: 0, ease: 'sine.inOut', delay: INITIAL_DELAY+4, duration: 0.6 });
      gsap.delayedCall(INITIAL_DELAY+1, () => { this.currentQuestion = 0; this.rotation = 0; })
      gsap.delayedCall(INITIAL_DELAY+5, () => { this.currentQuestion = 1; })

      gsap.delayedCall(INITIAL_DELAY+6,  () => { this.makeOkButtonJump = true; });
//      gsap.delayedCall(INITIAL_DELAY+8, () => { this.currentQuestion = 2; })
    },
    currentQuestion(newval) {
      if (this.currentQuestionIndex >= 0.5) {
        gsap.to(this, { duration: 0.3, currentQuestionIndex: 0, ease: 'linear', onComplete: () => {
          this.currentQuestionText = this.questions[newval];
          if (newval >= 2) { this.isShowingQuestions = true; }

          gsap.to(this, { duration: 0.8, currentQuestionIndex: 1, ease: 'linear' });
        } });
      } else {
        this.currentQuestionText = this.questions[newval];
        gsap.to(this, { duration: 0.8, currentQuestionIndex: 1, ease: 'linear' });
      }
    }
  },
  data: function() {
    return {
      rotation: 0, // -135 to +135 degrees
      currentQuestion: -1,
      currentQuestionText: '',
      isShowingQuestions: false,
      currentQuestionIndex: 0,
      currentQuestionHasChanged: false,
      makeOkButtonJump: false,
      questions: [
        'Gör en temperaturtagning – ställ in hur nöjd du är',
        'När du är klar – klicka på OK',
        'Hur levererar marknadssatsningarna just nu?',
        'Behovet av att trimma varumärke och ramverk?',
        'Din lust att ge teamet ny energi och fler verktyg?',
        'Hur sugen är du på att få ihop varumärket med säljet?',
      ],
      answers: [],
    }
  },
  computed: {
    currentText() {
      return this.currentQuestionText.substring(0, Math.round(this.currentQuestionText.length * this.currentQuestionIndex));
    },
    number() {
      let val = this.calculateValueFromRotation();
      return Math.round(val)+'%';
    },
  },
  methods: {
    getXYFromEvent(event) {
      if (!event.touches) {
        // MouseEvent
        return [ event.clientX, event.clientY ];
      }
      if (event.touches.length <= 0) {
        return [ null, null ];
      }
      return [ event.touches[0].clientX, event.touches[0].clientY ];
    },
    getRotationFromEvent(event) {
      const toucharea = this.$refs.toucharea;
      const touchareaRect = toucharea.getBoundingClientRect();
//      console.log(touchareaRect);
//      console.dir(event);
      const touchMidX = touchareaRect.left + touchareaRect.width/2;
      const touchMidY = touchareaRect.top + touchareaRect.height/2;
      const [ pressX, pressY ] = this.getXYFromEvent(event);
      if (pressX == null) return null; // No touches found

      const diffX = pressX - touchMidX;
      const diffY = pressY - touchMidY;

      let angleInDegrees = 90 + Math.atan2(diffY, diffX) * 180 / Math.PI;
      if (angleInDegrees >= 180) { angleInDegrees -= 360; }
      angleInDegrees = Math.min(135, Math.max(-135, angleInDegrees)); // Clip to acceptable range
//      console.log(diffX, diffY, angleInDegrees);
      return angleInDegrees;
    },
    knobClick(touchEvent) {
      if (this.currentQuestion < 2) return;

      let rot = this.getRotationFromEvent(touchEvent);
      if (rot == null) return;

      this.rotation = this.getRotationFromEvent(touchEvent)
      this.currentQuestionHasChanged = true;
    },
    knobDragStart(touchEvent) {
      if (this.currentQuestion < 2) return;

      this.rotation = this.getRotationFromEvent(touchEvent);
      this.currentQuestionHasChanged = true;
    },
    knobDragMove(touchEvent) {
      if (this.currentQuestion < 2) return;

      this.rotation = this.getRotationFromEvent(touchEvent);
    },
    knobDragRelease() {
    },
    calculateValueFromRotation() {
      if (this.rotation == null) return null;

      let percentOfTotal = (this.rotation + 135)/(135*2);
      return Math.round(percentOfTotal*100);
    },
    clickOk() {
      if (!this.currentQuestionHasChanged) return;
      if (this.currentQuestion == 0) return;

      let value = this.calculateValueFromRotation();
      if (this.currentQuestion != 2) { value = 100 - value; }
      this.answers.push(value);

      if (this.currentQuestion > this.questions.length - 2) {
        // const totalPercent = this.answers.reduce((a, b) => a + b, 0);
        // const averagePercent = Math.round(totalPercent / this.answers.length);      
        this.$emit('next', this.answers)
      } else {
        this.currentQuestion++;
        this.rotation = 0;
        this.currentQuestionHasChanged = false;
        console.log('clickOk', this.currentQuestion, this.questions.length);
      }
    },
    clickStart() {
      this.makeOkButtonJump = false;
      this.currentQuestion = 2;
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>


.radio {
  width: calc((780/1700) * 100vh);
  width: calc((780/1700) * var(--vh, 1vh) * 100);

  padding-bottom: calc((1700/780) * 100%);
  height: 0;
  position: relative;

  @media screen and (min-width: 768px) {
    width: calc((780/1700) * 95vh);
    width: calc((780/1700) * var(--vh, 1vh) * 95);
  }

  img.radio-image {
    width: 100%;
    height: auto;
  }

  img.radio-shadow {
    position: absolute;
    right: 1vh;
    bottom: -6vh;
    width: 200%;
    pointer-events: none;
    z-index: -1;
  }
}

.display {
  position: absolute;
  left: 0;
  bottom: 18%;
  width: 100%;
  height: 10%;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: var(--color-blue);
  font-family: var(--font-header);
  font-size: 60px;
}
.knob {
  position: absolute;
  top: 45.3%;
  height: 22.7%;
  left: 25.5%;
  width: 49.38%;
  border-radius: 50%;
  transition: all 0.15s linear;
  background: var(--color-blue-light);
  border: 6px solid var(--color-blue);
  box-sizing: border-box;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 8%;
    width: 5%;
    height: 5%;
    margin: -2.5% -2.5%;
    background: var(--color-blue);
    border-radius: 50%;
  }
}
.knob-touch {
  position: absolute;
  top: 41.3%;
  left: 16.5%;
  height: 30.85%;
  width: 67.3%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.0);
  cursor: pointer;
}

button.ok {
  padding: 0;
  margin: 0;
  background: var(--color-blue);
  border: 0.5vh solid var(--color-blue);
  border-radius: 50%;
  position: absolute;
  width: 22%;
  height: 10%;
  left: 50%;
  top: 56.6%;
  transform: translate(-50%, -50%);  
  color: var(--color-white);
  font-family: var(--font-question);
  font-size: 3vh;
  transition: width 0.25s ease-in-out, height 0.25s ease-in-out, transform 0.25s ease-in-out, background-color 0.8s linear, border-color 0.8s linear, color 0.8s linear;

  &:active, &:focus, &:hover {
    transform: translate(-50%, -50%) scale(0.97);
    // width: 21%;
    // height: 9.5%;
  }

  &.ok-disabled {
    color: rgba(255, 255, 255, 0.3);
    // background: var(--color-blue-disabled);
    // border-color: var(--color-blue-disabled);

    &:active, &:focus, &:hover {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  &.jump {
    animation: 2s ease-in-out 0s 1 jump;
  }
}
@keyframes jump {
  0%, 50%, 100% {
    transform: translate(-50%, -50%) scale(1);
    color: rgba(255, 255, 255, 0.3);
  }
  25%, 75% {
    transform: translate(-50%, -50%) scale(1.1);
    color: var(--color-white);
  }
}

.ok-shadow {
  position: absolute;
  background: transparent url(../assets/ok-shadowleft.svg) no-repeat top left;
  background-size: contain;
  top: 52%;
  left: 26.4%;
  width: 30%;
  height: 12%;
  pointer-events: none;
  transition: all 0.25s ease-in-out;
}
.ok-shadow:has(+ button.ok:hover:not(.ok-disabled)) {
  left: 28.4%;
  top: 52%;
  width: 29%;
  height: 11.6%;
}

.question {
  position: absolute;
  left: 5%;
  width: 90%;
  top: 24%;
  font-family: var(--font-text);
  color: var(--color-black);
  font-size: 24px;
  // font-size: 1.25rem;
  // font-size: 2.3vh; // Should we really do this? 🥵
  // font-size: calc(var(--vh, 1vh) * 2.3);

  @media screen and (min-width: 768px) {
    left: 0%;
    width: 100%;
    font-size: 24px;
  }

  &.is-question {
    color: var(--color-blue);
    font-family: var(--font-question);
  }

}

button.start {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 85%;
}

.indicators {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translateX(-50%);

  li {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 0 4px;
    background-color: var(--color-blue-light);
    transition: all 0.3s linear;

    &.active {
      background-color: var(--color-blue-dark);
      transition: all 0.6s linear;
    }
  }
}

</style>
