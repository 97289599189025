<template>
  <div class="sky" :class="'state-'+state">
    <img src="./assets/himmel.svg">
    <div class="sun"></div>
    <div class="cloud cloud1"></div>
    <div class="cloud cloud2"></div>
    <div class="cloud cloud3"></div>
    <div class="cloud cloud4"></div>
    <div class="cloud cloud5"></div>
    <div class="cloud cloud6"></div>
    <div class="cloud cloud7"></div>
    <div class="cloud cloud8"></div>
  </div>
  <div class="bkg" :class="'state-'+state"><img src="./assets/stad.svg"></div>
  <Welcome @next="gotoQuestionaire" :class="[state=='welcome'?'mid':(state=='questionare'?'left':'left')]"/>
  <Questions @next="gotoResult" :triggerAnimation="triggerQuestionAnimation" :class="[state=='welcome'?'right':(state=='questionare'?'mid':'left')]"/>
  <Result @next="gotoForm" :answers="answers" :state="state" :class="[state=='result'?'mid':'right']"/>
  <img src="./assets/logo-black.svg" alt="Change" class="logo" v-if="!showform">

  <transition name="slideup">
    <Form v-if="showform" class="form" @next="gotoThanks" />
  </transition>
  <transition name="slideup">
    <Thanks v-if="showThanks" class="form" />
  </transition>


</template>

<script>
import Welcome from './components/Welcome.vue';
import Questions from './components/Questions.vue';
import Result from './components/Result.vue';
import Form from './components/Form.vue'
import Thanks from './components/Thanks.vue'

export default {
  name: 'App',
  components: {
    Welcome,
    Questions,
    Result,
    Form,
    Thanks
  },
  data: function() {
    return {
      state: 'welcome',
      showform: false,
      showThanks: false,
      triggerQuestionAnimation: false,
      answers: [0, 0, 0, 0],
    }
  },
  methods: {
    gotoQuestionaire() {
      this.triggerQuestionAnimation = true;
      this.state = 'questionare';
    },
    gotoResult(_answers) {
      this.answers = _answers;
      this.state = 'result';
    },
    gotoForm() {
      console.log('gotoForm');
      this.showform = true;
    },
    gotoThanks() {
      console.log('gotoForm');
      this.showThanks = true;
    }
  }
}
</script>

<style lang="scss">

@import url("https://use.typekit.net/got4bfr.css");

:root {
  --color-black: #2B2B2B;
  --color-white: #FFFFFF;
  --color-gray: #A5A5A5;
  --color-gray-dark: #797979;
  --color-pink: #FFB3A2;
  --color-blue-text: #348F91;
  --color-blue-green: #237D84;
  --color-blue: #3BD0DC;
  --color-blue-light: #D6FFFD;
  --color-purple: #9C4DFA;
  --color-purple-border: #833FD4;
  --color-red: #FB145F;


  --color-gray: #EDE6E4;
  --color-orange: #ff6b00;
  --color-blue: #1A30E0;
  --color-blue-dark: #01206d;
  --color-blue-light: #CEF3FF;
  --color-blue-disabled: #70738d;
  --color-yellow: #ffe445;
  --color-beige: #f7f2e7;

  --color-button: var(--color-blue);
  --color-button-text: var(--color-white);
  --color-button-active: var(--color-blue-dark);
  --color-title: var(--color-blue);

  --font-text: 'open-sans', Arial, sans-serif;
  --font-header: 'bodoni-urw', Garamond, serif;
  --font-question: 'poppins', sans-serif;
}

html, body {
  margin: 0;
  height: 100%;
  overflow: hidden
}

body {
  padding: 0;
  font-size: 16px;
//  background: url(assets/himmel.svg) repeat-x top left;
//  background-size: 2000px auto;
  touch-action: none; // Stop zooming.
  background: #93D9EF;
}

#app {
  font-family: var(--font-text);
  font-weight: 300;
  line-height: 1.36;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-black);
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
}


.sky {
  position: absolute;
  top: -10vh;
  bottom: 0;
  left: 0;
  width: calc(3024/640 * 70vh);
  width: calc(3024/640 * 70 * var(--vh, 1vh));
  height: 70vh;
  height: calc(var(--vh, 1vh) * 70);
  transition: transform 5s 1s ease-in-out;

  &:before { // Filler element to show white bkg below sky.
    content: '';
    position: absolute;
    z-index:-1;
    left: 0;
    width: 300vw;
    height: 112vh;
    top: 30vh;
    background: var(--color-white);
  }

  img {
    border: 0;
    width: 100%;
    height: auto;
  }

  .cloud, .sun {
    position: absolute;
    left: 20%;
    top: 30%;
    width: 80px;
    height: 80px;
    background: #fff; //red;
    border-radius: 50%;

    animation: 5s ease-in-out 0s infinite cloudBubble;
  }
  .sun {
    // background: radial-gradient(circle, rgba(255,228,69,1) 39%, rgba(255,228,69,0) 100%);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    width: 4%;
    height: 19%;
    left: 11.25%;
    top: 27%;
    animation: 5s ease-in-out 0s infinite sunRays;
  }
  .cloud1 { left: 19.6%; top: 41.5%; width: 2.2%; height: 10.5%; animation-delay: 0s; }
  .cloud2 { left: 27.8%; top: 34%; width: 2.4%; height: 10%; animation-delay: 0.3s; }
  .cloud3 { left: 23%; top: 34%; width: 1.5%; height: 7.1%; animation-delay: 0.67s; }
  .cloud4 { left: 13.7%; top: 50%; width: 2.7%; height: 12.8%; animation-delay: 1.05s; }
  .cloud5 { left: 32%; top: 42%; width: 1.2%; height: 5.7%; animation-delay: 1.4s; }

  .cloud6 { left: 1.58%; top: 48%; width: 1.5%; height: 7.1%; animation-delay: 1.2s; }
  .cloud7 { left: 2.9%; top: 39%; width: 6.3%; height: 25.8%; animation-delay: 2.1s; animation-duration: 8s; }
  .cloud8 { left: 35.5%; top: 43%; width: 2.2%; height: 12.7%; animation-delay: 1.8s; animation-duration: 10s; }

  @keyframes cloudBubble {
    0%, 100% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.9, 1.1);
    }
    75% {
      transform: scale(1.1, 0.9);
    }
  }
  @keyframes sunRays {
    0%, 100% {
      transform: scale(0.8, 0.8);
    }
    50% {
      transform: scale(1, 1);
    }
  }

  &.state-welcome {
    transform: translateX(-10vh) scale(1);
  }
  &.state-questionare {
    transform: translate(0px, 20vh) scale(1.5);
  }
  &.state-result {
    transition: transform 4s 0s ease-in-out;

    transform: translate(-400px, 10vh) scale(1.5);
  }  

  @media screen and (min-width: 768px) { // Tablet Portrait
    &.state-welcome {
      transform: translateX(0vh) scale(1);
    }
  }


}

.bkg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -50vw;
  width: calc(3023/1140 * 70vh);
  width: calc(3023/1140 * 70 * var(--vh, 1vh));
  height: 70vh;
  height: calc(var(--vh, 1vh) * 70);
//  background: var(--color-blue) url(assets/bkg-smaller.png) no-repeat top left;
//  background-size: cover;
  transition: transform 4s 0.5s ease-in;

  &:before { // Filler element to show grey bkg below houses.
    content: '';
    position: absolute;
    z-index:-1;
    left: 0;
    width: 300vw;
    height: 112vh;
    bottom: -40vh;
    background: var(--color-gray);
  }

  img {
    border: 0;
    width: 100%;
    height: auto;
    margin-top: -23vh;
  }

  &.state-welcome {
    transform: translateY(50vh);
  }
  &.state-questionare,
  &.state-result {
    transform: translateY(200vh) scale(2);
  }


  @media screen and (min-width: 768px) { // Tablet Portrait
    left: -15vw;
    width: calc(3023/1140 * 70vh);
    width: calc(3023/1140 * 70 * var(--vh, 1vh));
    height: 70vh;
    height: calc(var(--vh, 1vh) * 70);

    &.state-welcome {
      transform: translateY(50vh);
    }
    &.state-questionare,
    &.state-result {
      transform: translateY(200vh) scale(2);
    }    
  }
  @media screen and (min-width: 1025px) { // Desktop
    left: 0;
  }
}

.left, .mid, .right {
  position: absolute;
  top: 0;
  transition: all 1.9s ease-in-out;
//  transform: translateX(-50%);
}
.left {
  left: -100vw;
  transition: all 1.9s ease-in;
}
.mid {
  left: 0vw;
  transition: all 1.9s ease-out;
}
.welcome.left {
  transition: all 1s ease-in;
}
.questions.mid {
  left: 50vw;
  transition: all 1.9s 2.5s ease-in;
  transform: translateX(-50%);
}
.result.mid {
  transition-delay: 0.5s;
  left: 50vw;
  transform: translateX(-50%);
}
.right {
  left: 170vw;
}


h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0.5em 0;
  font-family: var(--font-header);
  color: var(--color-title);
  font-weight: 900;
  text-transform: uppercase;
}

h1 {
  font-size: 1.75rem;
  line-height: 1.2;
  padding-bottom: 0;
}
h2 {
  font-size: 1.5rem;
  line-height: 1.25;
  font-family: var(--font-question);
  text-transform: none;
}
h3 {
  font-size: 1.25rem;
  line-height: 1.35;
}
h4 {
  font-size: 1rem;
  line-height: 1.4;
}
p {
  font-size: 18px;
  margin: 0.5em 0;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 3rem;
    padding: 0.25em 0;
  }
}

button, input[type=submit] {
  border: 0;
  border-radius: 2em;
  padding: 0.75em 2em;
  color: var(--color-button-text);
  background-color: var(--color-button);
  font-size: 18px;
  margin: 0.5em 0;

  &:hover {
    background-color: var(--color-button-active);
    cursor: pointer;
  }
}

.logo {
  position: fixed;
  z-index: 100;
  top: 1rem;
  left: 1rem;
  width: 80px;
  height: auto;
}


.form, .thanks {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}


/* TRANSITIONS */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fadedelay-enter-active,
.fadedelay-leave-active {
  transition: opacity 0.5s ease;
}
.fadedelay-enter-from,
.fadedelay-leave-to {
  opacity: 0;
}
.fadedelay-enter-active {
  transition-delay: 2s;
}


.slowfade-enter-active,
.slowfade-leave-active {
  transition: opacity 1.5s ease;
}
.slowfade-enter-from,
.slowfade-leave-to {
  opacity: 0;
}
.slideup-enter-active,
.slideup-leave-active {
  transition: all 1s ease-in;
}
.slideup-enter-to,
.slideup-leave-from {
top: 0;
}

.slideup-enter-from,
.slideup-leave-to {
top: 100vh;
}

</style>
