<template>
  <div class="welcome">
    <h1>Om tre minuter börjar semestern på riktigt</h1>
    <p>Ah! Äntligen sommar. Börja inte grubbla över hur du ska få koll på varumärket och teamet runt omkring dig. Gör vårt test istället! Sedan är det bara att hoppa upp i hängmattan och njuta av vetskapen att allt kommer lösa sig till hösten.</p>
    <button @click="gotoNext">Gör testet</button>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  emits: ["next"],
  methods: {
    gotoNext() {
      this.$emit('next');
    }
  } 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.welcome {
  width: 85vw;
  margin-top: 0;
  min-width: 280px;
  max-width: 420px;
  padding: 0 20px;

  top: 55%;
  transform: translateY(-50%);    

  h1 {
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 14px;
    margin-top: 35vh;
  }

 
  @media screen and (min-width: 768px) {
    width: 70vw;
    min-width: 300px;
    max-width: 420px;

    padding: 0 15vw;
    max-width: 520px;
    top: 50%;

    p {
      font-size: 16px;
      margin-top: 35vh;
    }

  }
  @media screen and (min-width: 1360px) { // Desktop wide
    padding: 0 20vw;
  }

}

/*
p {
  text-shadow: 0px 0px 8px var(--color-pink), 0px 0px 4px var(--color-pink), 0px 0px 2px var(--color-pink), 0px 0px 1px var(--color-pink);
}
*/
</style>
