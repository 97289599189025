<template>
  <div class="result" :class="'state-'+state">
    <h2>Ditt resultat</h2>
    <p v-if="isPerfect">Imponerande! Ja, du verkar ha de mesta under kontroll. Men häng kvar om du vill att vi kontakta dig ändå – även den bästa kan behöva lite hjälp ibland!</p>
    <p v-else>Det finns lite att göra! Men bara lugn: När det finns utrymme för förbättring, finns även hjälpen nära. Vi kan lösa allt ihop efter semestern. Du har allt att vinna på att komma vidare!</p>

    <ul class="grid">
      <li class="aktivering" :class="'result-'+(answers[0]<=50?'red':(answers[0]<=90?'yellow':''))"><p>Aktivering</p></li>
      <li class="branding" :class="'result-'+(answers[1]<=25?'red':(answers[1]<=75?'yellow':''))"><p>Branding</p></li>
      <li class="inhouse" :class="'result-'+(answers[2]<=25?'red':(answers[2]<=75?'yellow':''))"><p>Inhouse</p></li>
      <li class="synergier" :class="'result-'+(answers[3]<=25?'red':(answers[3]<=75?'yellow':''))"><p>Synergier</p></li>
    </ul>

    <button @click="showForm">Gå vidare</button>
  </div>
</template>

<script>
export default {
  name: 'Result',
  emits: ["next"],
  components: {
  },
  data() {
    return {
      show: false
    }
  },
  props: {
    answers: Array,
    state: String,
  },
  computed: {
    isPerfect() {
      return (this.answers[0]>90 && this.answers[1]>90 && this.answers[2]>90 && this.answers[3]>90);
    }
  },
  methods: {
    showForm() {
      this.$emit('next');
      // Blipp blopp!
    },
  } 
}
</script>

<style lang="scss" scoped>
.result {
  width: 70vw;
  padding: 0 10vw;
  margin-top: 10vh;
  min-width: 320px;
  max-width: 450px;

  @media screen and (min-width: 768px) {
    margin-top: 20vh;
  }
}

h2 {
  font-family: var(--font-question);
  font-weight: 700;
  text-transform: none;
}
.grid {
  list-style: none;
  padding: 0;
  margin: 1em auto;
  overflow: hidden;
  width: 250px;
  height: 230px;
  border-radius: 2em;

  display: grid;
  grid-template-columns: 123px 123px;
  grid-row: 113px 113px;
  grid-column-gap: 4px;
  grid-row-gap: 4px;

  li {
    display:flex;
    align-items: flex-end;
    justify-content:center;
    background: var(--color-gray) url(../assets/status-good.svg) no-repeat center 30px;
    transition: all 1s 1s;

    p {
      font-family: var(--font-question);
      color: var(--color-white);
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      margin: 16px 0;
    }

    &.result-red {
      background: var(--color-gray) url(../assets/status-bad.svg) no-repeat center 30px;
    }
    &.result-yellow {
      background: var(--color-gray) url(../assets/status-mid.svg) no-repeat center 30px;
    }
  }
}

.state-result {
  .grid {
    li {
      &:nth-child(1) {
        transition-delay: 2s;
      }
      &:nth-child(2) {
        transition-delay: 2.5s;
      }
      &:nth-child(3) {
        transition-delay: 3s;
      }
      &:nth-child(4) {
        transition-delay: 3.5s;
      }

      background-color: #9ED352;
      &.result-red {
        background-color: #C93A1B;
      }
      &.result-yellow {
        background-color: #F49716;
      }
    }
  }
}

button {
  display: block;
  margin: 3em auto 0; 
}

</style>
