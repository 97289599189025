<script setup>
  import { reactive, ref, defineEmits } from 'vue'

  const state = reactive({
    name: null,
    title: null,
    company: null,
    email: null,
    phone: null,
    checkbox: false
  }) 

  const notValid = ref(null)
  const emit = defineEmits('next')

  const sendForm = () => {
    if (state.name && state.email && state.phone && state.title && state.company) {
      notValid.value = false
      fetch(`https://treminuter.changetheagency.se/backend/?namn=${state.name}&epost=${state.email}&mobil=${state.phone}&titel=${state.title}&foretag=${state.company}`, {
        method: 'GET',
      })
      .then(response => {
        if(response.ok) {
          emit('next')
        }
      })
      .catch(err => {
        console.log(err);
      })

      emit('next');
    } else {
      notValid.value = true
    }
  }


</script>

<template>
  <div class="wrapper">
    <div class="container">
      <h2>Dina uppgifter</h2>
      <div class="flex-form">
        
        <div class="input-container">
          <input v-model="state.name" type="text" name="name" placeholder=" ">
          <label class="field-label" for="name">Namn</label>
        </div>

        <div class="input-container">
          <input v-model="state.title" type="text" name="title" placeholder=" ">
          <label class="field-label" for="title">Titel</label>
        </div>

        <div class="input-container">
          <input v-model="state.company" type="text" name="company" placeholder=" ">
          <label class="field-label" for="company">Företag</label>
        </div>

        <div class="input-container">
          <input v-model="state.email" type="text" name="email" placeholder=" ">
          <label class="field-label" for="email">E-post</label>
        </div>

        <div class="input-container">
          <input v-model="state.phone" type="text" name="phone" placeholder=" ">
          <label class="field-label" for="phone">Mobilnummer</label>
        </div>

        <div class="flex-switch">
          <label class="switch">
            <input v-model="state.checkbox" type="checkbox">
            <span class="slider round"></span>
          </label>
          <span>Jag godkänner att mina uppgifter sparas av Change i syfte av att kontakta mig</span>
          </div>
          <p v-if="notValid" class="error">Vänligen fyll i samtliga fält.</p>
          <button :disabled="!state.checkbox" @click="sendForm">Skicka</button>
      </div>
    </div>

    <img src="../assets/logo-white.svg" alt="Change" class="logo">
  </div>
</template>

<style scoped lang="scss">
h2 {
  color: var(--color-white);
  font-family: var(--font-question);
  text-transform: none;
}
.wrapper {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: var(--color-blue-dark);
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 64px 24px 140px;
//  overflow: hidden;
  max-width: 375px;
}

.logo {
  position: fixed;
  z-index: 100;
  top: auto;
  bottom: 1rem;
  left: 1rem;
  width: 80px;
  height: auto;
}

textarea {
  resize: none;
  height: 132px;
}
button {
  width: 180px;
  z-index: 1;
  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    background-color: var(--color-blue);
  }
}
h2 {
  padding: 0 0 0.5em;
}
.error {
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--color-red)
}
.flex-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  .input-container {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .field-label, .textarea-label {
    position: absolute;
    background-color: var(--color-blue-light);
    font-size: 12px;
    line-height: 14px;
    bottom: 42px;
    left: 21px;
    border-radius: 4px;
    padding: 1px 3px;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
  }

  input[type="text"], textarea {
    &:focus {
      & + .field-label, & + .textarea-label {
        background-color: var(--color-white);
      }
    }
  }
  .textarea-label {
    bottom: 148px;

  }
}
.flex-switch {
  display: flex;

  .switch {
    width: 85px;
  }
  span {
    color: var(--color-white);
    width: 90%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
}

input[type="text"], textarea {
  max-width: 335px;
  padding: 11px 20px;
  border: 0;
  border-radius: 28px;
  background-color: var(--color-blue-light);
  font-size: 18px;
  line-height: 26px;
  color: var(--color-blue-text);
  outline: none;

  &:focus {
    background-color: var(--color-white);
  }
}

// input + label { margin-top: 0; font-size: 0.5rem; }
input:placeholder-shown + .field-label { 
  bottom: 19px;
  font-size: 1rem;
  padding: 0;
}
textarea:placeholder-shown + .textarea-label { 
  bottom: 125px;
  font-size: 1rem;
  padding: 0;
      &::after {
      content: ' i en mening'
    }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 67px;
  height: 32px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-blue-light);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 3px;
  background-color: var(--color-blue);
  -webkit-transition: .4s;
  transition: .4s;
  opacity: 0.5;
}

input:checked + .slider {
  background-color: var(--color-blue-light);
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--color-blue-light);
}

input:checked + .slider:before {
  opacity: 1;
  -webkit-transform: translateX(33px);
  -ms-transform: translateX(33px);
  transform: translateX(33px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


@media screen and (min-width: 768px) { // Tablet Portrait
  .wrapper {
    padding: 50px;
  }
  .blob {
    left: 342px;
  }
}
@media screen and (min-width: 768px) and (orientation: landscape) { // Tablet Portrait
  .wrapper {
    padding: 50px;
  }

}
@media screen and (min-width: 1360px) { // Desktop wide
  .wrapper {
    padding: 15vh 0 0 145px;
  }
}


</style>