import { createApp } from 'vue'
import Vue3TouchEvents from "vue3-touch-events";
import App from './App.vue'

const app = createApp(App);
app.use(Vue3TouchEvents, {
  dragFrequency: 1000/60  // 60 fps
});
app.mount('#app');


const updateVhVariable = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
window.addEventListener('resize', updateVhVariable);
updateVhVariable();