<script setup>
const toChange = () => {
  document.location = 'https://www.changetheagency.se/'
}
</script>

<template>
  <div class="wrapper thanks">
    <div class="container">
      <h2>Tack!</h2>
      <p>Kom ihåg att små förändringar kan göra stora underverk. Jenny Plasidius kommer höra av sig till dig efter sommaren och berätta mer. Kul att du ville göra testet. Vi tackar så mycket och önskar dig en underbar semester!</p>
      </div>
    </div>
    <button @click="toChange()">Till&nbsp;changetheagency.se</button>
</template>

<style scoped lang="scss">
.thanks {
  width: 100vw;
  height: 100vh;
  background: var(--color-gray) url(../assets/tacksida.svg) no-repeat center center;
  background-size: 1667px auto;
}
.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-52%, -280px);
  width: 80vw;
  max-width: 375px;
}
h2 {
  padding: 0;
}
p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
}

button {
  position: absolute;
  left: 50%;
  bottom: 2em;
  transform: translateX(-50%);
}


@media screen and (min-width: 768px) { // Tablet Portrait
  .container {
  }
}
@media screen and (min-width: 768px) and (orientation: landscape) { // Tablet Portrait
  .container {
  }
}
@media screen and (min-width: 1360px) { // Desktop wide
  .thanks {
  }
  .container {
  }
}



</style>